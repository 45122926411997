"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Storage = void 0;
const debug_1 = require("debug");
const utils_1 = require("./utils");
const log = debug_1.debug('xumm-sdk:storage');
class Storage {
    constructor(MetaObject) {
        log('Constructed');
        this.Meta = MetaObject;
    }
    get() {
        return __awaiter(this, void 0, void 0, function* () {
            const call = yield this.Meta.call('app-storage', 'GET');
            utils_1.throwIfError(call);
            return call.data;
        });
    }
    set(data) {
        return __awaiter(this, void 0, void 0, function* () {
            const call = yield this.Meta.call('app-storage', 'POST', data);
            utils_1.throwIfError(call);
            return call.stored;
        });
    }
    delete() {
        return __awaiter(this, void 0, void 0, function* () {
            const call = yield this.Meta.call('app-storage', 'DELETE');
            utils_1.throwIfError(call);
            return call.stored;
        });
    }
}
exports.Storage = Storage;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DeferredPromise = exports.throwIfError = void 0;
function throwIfError(call) {
    var _a, _b, _c, _d, _e, _f;
    const isFatalError = call.message !== undefined;
    if (isFatalError) {
        throw new Error(call.message);
    }
    const isError = call.next === undefined
        && ((_b = (_a = call) === null || _a === void 0 ? void 0 : _a.meta) === null || _b === void 0 ? void 0 : _b.uuid) === undefined
        && ((_d = (_c = call) === null || _c === void 0 ? void 0 : _c.application) === null || _d === void 0 ? void 0 : _d.uuidv4) === undefined
        && ((_f = (_e = call) === null || _e === void 0 ? void 0 : _e.error) === null || _f === void 0 ? void 0 : _f.code) !== undefined;
    if (isError) {
        const e = call.error;
        /**
         * TODO:
         *   add local error code list + descriptions?
         *   (note: can be endpoint specific,)
         */
        throw new Error(`Error code ${e.code}, see XUMM Dev Console, reference: ${e.reference}`);
    }
}
exports.throwIfError = throwIfError;
class DeferredPromise {
    constructor() {
        this.resolveFn = (_arg) => {
            // Will be replaced by Promise fn
        };
        this.rejectFn = (_arg) => {
            // Will be replaced by Promise fn
        };
        this.promise = new Promise((resolve, reject) => {
            this.resolveFn = resolve;
            this.rejectFn = reject;
        });
    }
    resolve(arg) {
        this.resolveFn(arg);
        return this.promise;
    }
    reject(arg) {
        this.rejectFn(arg);
        return this.promise;
    }
}
exports.DeferredPromise = DeferredPromise;
